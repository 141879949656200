import React, { useEffect, useState } from "react";
import { useToast } from "@/components/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { APP_VERSION } from "../version";
import { updater, app } from "@neutralinojs/lib";

const SIMULATE_OUT_OF_DATE = false;

// Define a type for the Neutralino global object
declare global {
  interface Window {
    Neutralino?: {
      os: any;
      updater: {
        checkForUpdates: (url: string) => Promise<{ version: string }>;
        install: () => Promise<void>;
      };
      app: {
        restartProcess: () => void;
        exit: () => void;
      };
    };
  }
}

// Type guard to check if Neutralino is available
function isNeutralinoAvailable(): boolean {
  return typeof window !== "undefined" && !!window.NL_APPVERSION;
}

function compareVersions(v1: string, v2: string): number {
  if (SIMULATE_OUT_OF_DATE) {
    return 1;
  }
  const parts1 = v1.split(".").map(Number);
  const parts2 = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;
    if (part1 > part2) return 1;
    if (part1 < part2) return -1;
  }
  return 0;
}

const NeutralinoUpdater: React.FC = () => {
  const { toast } = useToast();
  const [lastDismissedTime, setLastDismissedTime] = useState<number | null>(
    null
  );

  const checkForUpdates = async () => {
    try {
      let manifest;
      if (isNeutralinoAvailable()) {
        manifest = await updater.checkForUpdates(
          "https://download-staging.frontierxai.com/app/v1/updates/manifest.json"
        );
      } else {
        const response = await fetch(
          "https://download-staging.frontierxai.com/app/v1/updates/manifest.json"
        );
        manifest = await response.json();
      }
      const currentVersion = APP_VERSION;
      const latestVersion = manifest.version;

      if (compareVersions(latestVersion, currentVersion) > 0) {
        const now = Date.now();
        if (
          !lastDismissedTime ||
          now - lastDismissedTime > 2 * 60 * 60 * 1000
        ) {
          showUpdateToast();
        }
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  };

  const handleUpdate = async () => {
    if (isNeutralinoAvailable()) {
      try {
        await updater.install();
        app.restartProcess();
      } catch (error) {
        console.error("Error updating application:", error);
      }
    } else {
      console.log("Update not available in browser environment");
    }
  };

  const showUpdateToast = () => {
    toast({
      title: "An app update is available",
      description: "Update to get the latest features",
      action: (
        <Button variant="outline" onClick={handleUpdate}>
          Update
        </Button>
      ),
      duration: Infinity,
      onOpenChange: (open) => {
        if (!open) {
          setLastDismissedTime(Date.now());
        }
      },
    });
  };

  useEffect(() => {
    // Check for updates on startup
    checkForUpdates();

    // Check for updates every 5 minutes
    const intervalId = setInterval(checkForUpdates, 5 * 60 * 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [lastDismissedTime]);

  // This component doesn't render anything directly
  return null;
};

export default NeutralinoUpdater;
