import { useCallManager } from "./useCallManager";
import { CallManagerUI } from "./CallManagerUI";
import { CallManagerWrapper } from "./CallManagerWrapper";
import PartySocket from "partysocket";

export function CallManager({
  host,
  partySocket,
  authServiceUrl,
}: {
  host: string;
  partySocket: PartySocket;
  authServiceUrl: string | null;
}) {
  const callManagerProps = useCallManager(host);

  return (
    <CallManagerWrapper>
      <CallManagerUI
        {...callManagerProps}
        partySocket={partySocket}
        authServiceUrl={authServiceUrl}
      />
    </CallManagerWrapper>
  );
}
