import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface AiHelpCardNextPreviousProps {
  handlePrevious: () => void;
  handleNext: () => void;
  currentIndex: number;
  historyLength: number;
}

export function AiHelpCardNextPrevious({
  handlePrevious,
  handleNext,
  currentIndex,
  historyLength,
}: Readonly<AiHelpCardNextPreviousProps>) {
  return (
    <div className="flex justify-between">
      <Button
        onClick={handlePrevious}
        disabled={currentIndex === 0}
        variant="link"
        size="sm"
      >
        <ChevronLeft
          size={16}
          className={currentIndex === 0 ? "text-gray-400" : "text-gray-900"}
        />
      </Button>
      <Button
        onClick={handleNext}
        disabled={currentIndex === historyLength - 1}
        variant="link"
        size="sm"
      >
        <ChevronRight
          size={16}
          className={
            currentIndex === historyLength - 1
              ? "text-gray-400"
              : "text-gray-900"
          }
        />
      </Button>
    </div>
  );
}
