import { useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useOrganization } from "@clerk/clerk-react";
import { Separator } from "@/components/ui/separator";
import { DocumentUploader } from "./Knowledge/DocumentUploader";
import { DocumentList } from "./Knowledge/DocumentList";
import {
  KnowledgeDocument,
  KnowledgeDocumentSchema,
  KnowledgeDocumentWithTask,
  KnowledgeDocumentWithTaskSchema,
} from "./Knowledge/types/Document";
import { useKnowledgeUploadQueue } from "./useKnowledgeUploadQueue";
import PartySocket from "partysocket";
import z from "zod";

const DocumentsResponseSchema = z.array(KnowledgeDocumentSchema);

type DocumentsResponse = z.infer<typeof DocumentsResponseSchema>;

export function OrganisationKnowledgeManagerUI({
  partySocket,
}: {
  partySocket: PartySocket;
}) {
  const { organization } = useOrganization();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { addToQueue, queue, isUploading, refreshQueue } =
    useKnowledgeUploadQueue(partySocket);

  const fetchDocuments = async (
    orgId: string
  ): Promise<KnowledgeDocument[]> => {
    const response = await PartySocket.fetch(
      {
        host: partySocket.host,
        party: "main",
        room: partySocket.room ?? "waiting-room",
        path: "api/knowledge/list",
      },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orgId }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return DocumentsResponseSchema.parse(result);
  };

  const deleteDocument = async (key: string) => {
    if (!organization) return;

    try {
      const response = await PartySocket.fetch(
        {
          host: partySocket.host,
          party: "main",
          room: partySocket.room ?? "waiting-room",
          path: "api/knowledge/delete-file",
        },
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ key, orgId: organization.id }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const parsedResult = z
        .object({ success: z.boolean(), error: z.string().optional() })
        .parse(result);

      if (parsedResult.success) {
        queryClient.invalidateQueries({
          queryKey: ["documents", organization.id],
        });
        refreshQueue(); // Refresh the queue after successful deletion
      } else {
        throw new Error(parsedResult.error || "Deletion failed");
      }
    } catch (error) {
      console.error("Deletion failed:", error);
      alert("Failed to delete file. Please try again.");
    }
  };

  const {
    data: documentsData,
    isLoading,
    isError,
  } = useQuery<DocumentsResponse, Error>({
    queryKey: ["documents", organization?.id],
    queryFn: () => fetchDocuments(organization?.id || ""),
    enabled: !!organization?.id,
  });

  const documents = Array.isArray(documentsData) ? documentsData : [];

  const deleteMutation = useMutation({
    mutationFn: deleteDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["documents", organization?.id],
      });
      refreshQueue(); // Refresh the queue after successful deletion
    },
    onError: (error) => {
      console.error("Error deleting document:", error);
    },
  });

  const handleUpload = async (selectedFiles: File[]) => {
    if (selectedFiles.length === 0 || !organization) return;

    for (const file of selectedFiles) {
      await addToQueue(file, organization.id);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    queryClient.invalidateQueries({ queryKey: ["documents", organization.id] });
  };

  const handleDelete = (documentId: string) => {
    if (organization?.id) {
      deleteMutation.mutate(documentId);
    }
  };

  const combinedDocuments: KnowledgeDocumentWithTask[] = (documents || []).map(
    (doc) => {
      const queueTask = queue.find((task) => task.key === doc.key);
      return KnowledgeDocumentWithTaskSchema.parse({
        ...doc,
        task: queueTask
          ? {
              id: queueTask.id,
              status: queueTask.status,
              progress: queueTask.progress,
            }
          : undefined,
      });
    }
  );

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex-grow overflow-hidden">
        <div className="h-full flex flex-col space-y-8 p-4">
          <DocumentUploader
            onUpload={handleUpload}
            isUploading={isUploading}
            fileInputRef={fileInputRef}
          />
          <Separator />
          <div className="flex-grow overflow-hidden">
            <DocumentList
              documents={combinedDocuments}
              isLoading={isLoading}
              isError={isError}
              onDelete={handleDelete}
              isDeleting={deleteMutation.isPending}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
