import { openDB, DBSchema, IDBPDatabase } from "idb";
import PartySocket from "partysocket";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

interface UploadDB extends DBSchema {
  knowledgeUploadQueue: {
    key: string;
    value: UploadTask;
    indexes: { "by-status": string; "by-key": string };
  };
  uploadResults: {
    key: string;
    value: {
      key: string;
      pages: string[];
    };
  };
}

export interface UploadTask {
  id: string;
  file: File;
  organizationId: string;
  status: "pending" | "uploading" | "processing" | "completed" | "error";
  progress: number;
  startTime: number;
  error?: string;
  key?: string;
}

const DB_NAME = "KnowledgeUploadQueueDB";
const STORE_NAME = "knowledgeUploadQueue";
const RESULTS_STORE_NAME = "uploadResults";
const DB_VERSION = 4;

export function useKnowledgeUploadQueue(partySocket: PartySocket) {
  const dbRef = useRef<IDBPDatabase<UploadDB> | null>(null);
  const [queue, setQueue] = useState<UploadTask[]>([]);
  const queryClient = useQueryClient();

  const TIMEOUT_DURATION = 5 * 60 * 1000;

  const initDB = useCallback(async () => {
    console.log("initDB called");
    if (!dbRef.current) {
      try {
        console.log(`Opening database: ${DB_NAME}, version: ${DB_VERSION}`);
        dbRef.current = await openDB<UploadDB>(DB_NAME, DB_VERSION, {
          upgrade(db, oldVersion, newVersion, transaction) {
            console.log(
              `Upgrading database from version ${oldVersion} to ${newVersion}`
            );
            if (!db.objectStoreNames.contains(STORE_NAME)) {
              console.log(`Creating object store: ${STORE_NAME}`);
              const store = db.createObjectStore(STORE_NAME, { keyPath: "id" });
              store.createIndex("by-status", "status");
              store.createIndex("by-key", "key");
            }
            if (!db.objectStoreNames.contains(RESULTS_STORE_NAME)) {
              console.log(`Creating object store: ${RESULTS_STORE_NAME}`);
              db.createObjectStore(RESULTS_STORE_NAME, { keyPath: "key" });
            }
          },
        });
        console.log("Database opened successfully");
      } catch (error) {
        console.error("Error opening database:", error);
        throw error;
      }
    }
    return dbRef.current;
  }, []);

  const refreshQueue = useCallback(async () => {
    const db = await initDB();
    const tasks = await db.getAll(STORE_NAME);
    setQueue(tasks);
  }, [initDB]);

  useEffect(() => {
    initDB()
      .then(() => {
        console.log("Database initialized");
        refreshQueue();
      })
      .catch((error) => {
        console.error("Failed to initialize database:", error);
      });

    return () => {
      if (dbRef.current) {
        dbRef.current.close();
      }
    };
  }, [initDB, refreshQueue]);

  const updateTaskStatus = useCallback(
    async (
      id: string,
      status: UploadTask["status"],
      error?: string,
      key?: string
    ) => {
      const db = await initDB();
      const task = await db.get(STORE_NAME, id);
      if (task) {
        const updatedTask = {
          ...task,
          status,
          error: error || task.error,
          key: key !== undefined ? key : task.key,
        };
        await db.put(STORE_NAME, updatedTask);
        await refreshQueue();
      }
    },
    [initDB, refreshQueue]
  );

  const checkForStuckJobs = useCallback(async () => {
    const db = await initDB();
    const now = Date.now();
    const tasks = await db.getAll(STORE_NAME);

    for (const task of tasks) {
      if (
        (task.status === "uploading" || task.status === "processing") &&
        now - task.startTime > TIMEOUT_DURATION
      ) {
        await updateTaskStatus(task.id, "error", "Operation timed out");
      }
    }
  }, [initDB, updateTaskStatus]);

  useEffect(() => {
    const intervalId = setInterval(checkForStuckJobs, 60000); // Check every minute
    checkForStuckJobs(); // Check immediately
    return () => clearInterval(intervalId);
  }, [checkForStuckJobs]);

  const uploadSequentially = useCallback(
    async (files: File[], organizationId: string) => {
      const processingPromises: Promise<void>[] = [];

      for (const file of files) {
        const newTask: UploadTask = {
          id: Date.now().toString(),
          file,
          organizationId,
          status: "pending",
          progress: 0,
          startTime: Date.now(),
          key: undefined,
        };

        const db = await initDB();
        await db.add(STORE_NAME, newTask);
        await refreshQueue();

        try {
          await updateTaskStatus(newTask.id, "uploading");
          console.log("Process: upload file");
          const uploadResult = await uploadFile(
            file,
            newTask.id,
            organizationId,
            partySocket
          );

          console.log("Upload result", uploadResult);
          console.log("Process: store in uploadResults");
          await db.put(RESULTS_STORE_NAME, {
            key: uploadResult.key,
            pages: uploadResult.pages,
          });

          console.log("Process: update task status", { uploadResult });
          await updateTaskStatus(
            newTask.id,
            "processing",
            undefined,
            uploadResult.key
          );

          queryClient.invalidateQueries({
            queryKey: ["documents", organizationId],
          });

          // Start processing immediately and add the promise to the array
          const processingPromise = processPages(
            uploadResult.key,
            uploadResult.pages,
            organizationId,
            newTask.id
          );
          processingPromises.push(processingPromise);
        } catch (error) {
          console.error("Upload failed:", error);
          await updateTaskStatus(newTask.id, "error", (error as Error).message);
        }
      }

      // Wait for all processing to complete
      await Promise.all(processingPromises);

      console.log("Process: all uploads and processing done! Refresh queue");
      await refreshQueue();
    },
    [initDB, partySocket, refreshQueue, updateTaskStatus, queryClient]
  );

  const addToQueue = useCallback(
    async (files: File | File[], organizationId: string) => {
      if (Array.isArray(files)) {
        await uploadSequentially(files, organizationId);
      } else {
        await uploadSequentially([files], organizationId);
      }
    },
    [uploadSequentially]
  );

  const uploadFile = async (
    file: File,
    taskId: string,
    organizationId: string,
    partySocket: PartySocket
  ) => {
    const arrayBuffer = await file.arrayBuffer();

    const response = await PartySocket.fetch(
      {
        host: partySocket.host,
        party: "main",
        room: partySocket.room ?? "waiting-room",
        path: "api/knowledge/upload",
      },
      {
        method: "POST",
        headers: {
          "Content-Type": file.type,
          "X-File-Name": encodeURIComponent(file.name),
          "X-Org-Id": organizationId,
        },
        body: arrayBuffer,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (!result.success) {
      throw new Error(result.error || "Unknown error occurred");
    }

    return result;
  };

  const processPages = async (
    key: string,
    pages: string[],
    organizationId: string,
    taskId: string
  ) => {
    console.log("Process pages", key, pages);
    const db = await initDB();
    const totalPages = pages.length;

    for (let i = 0; i < totalPages; i++) {
      try {
        await PartySocket.fetch(
          {
            host: partySocket.host,
            party: "main",
            room: partySocket.room ?? "waiting-room",
            path: "api/knowledge/process-page",
          },
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              key,
              page: `${i + 1}`,
              text: pages[i],
              organizationId,
              pageIndex: i,
            }),
          }
        );

        await updateTaskProgress(taskId, ((i + 1) / totalPages) * 100);
      } catch (error) {
        console.error(`Error processing page ${i + 1}:`, error);
        await updateTaskStatus(
          taskId,
          "error",
          `Error processing page ${i + 1}`
        );
        return;
      }
    }

    await updateTaskStatus(taskId, "completed");
    await db.delete(RESULTS_STORE_NAME, key);
  };

  const updateTaskProgress = useCallback(
    async (id: string, progress: number) => {
      const db = await initDB();
      const task = await db.get(STORE_NAME, id);
      if (task) {
        const updatedTask = { ...task, progress };
        await db.put(STORE_NAME, updatedTask);
        await refreshQueue();
      }
    },
    [initDB, refreshQueue]
  );

  const getTaskByDocumentKey = useCallback(
    async (documentKey: string): Promise<UploadTask | undefined> => {
      const db = await initDB();
      const allTasks = await db.getAll(STORE_NAME);
      return allTasks.find((task) => task.key === documentKey);
    },
    [initDB]
  );

  const removeTask = useCallback(
    async (id: string) => {
      const db = await initDB();
      await db.delete(STORE_NAME, id);
      await refreshQueue();
    },
    [initDB, refreshQueue]
  );

  const isUploading = queue.some((task) => task.status === "uploading");

  return {
    addToQueue,
    queue,
    getTaskByDocumentKey,
    removeTask,
    isUploading,
    refreshQueue,
  };
}
