import { useState } from "react";
import { SettingsMenu } from "./SettingsMenu";
import { OrganisationKnowledgeManagerUI } from "./OrganisationKnowledgeManagerUI";
import PartySocket from "partysocket";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CallScriptManagerUI } from "./Script/CallScriptManagerUI";

export const SettingsManagerUI = ({
  partySocket,
  onClose,
}: {
  onClose: () => void;
  partySocket: PartySocket;
}) => {
  const [activeMode, setActiveMode] = useState<"knowledge" | "script">(
    "knowledge"
  );
  return (
    <div className="flex flex-col h-full max-h-screen overflow-hidden">
      <div className="grid grid-cols-3 items-center w-full p-4">
        <div></div>
        <h2 className="text-center text-md font-semibold">Settings</h2>
        <div className="flex justify-end">
          <Button onClick={onClose} variant="ghost">
            <X className="h-6 w-6" />
          </Button>
        </div>
      </div>

      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="flex justify-center">
          <SettingsMenu activeMode={activeMode} setActiveMode={setActiveMode} />
        </div>
        <div className="flex-grow overflow-hidden">
          {activeMode === "knowledge" && (
            <OrganisationKnowledgeManagerUI partySocket={partySocket} />
          )}
          {activeMode === "script" && (
            <CallScriptManagerUI partySocket={partySocket} />
          )}
        </div>
      </div>
    </div>
  );
};
