import { useState, useCallback } from "react";
import usePartySocket from "partysocket/react";
import { useAnalytics } from "../AnalyticsProvider";
import { useOrganization, useUser } from "@clerk/clerk-react";
import type { Call, LogMessage } from "party/types";
import { CallScript } from "./Script/types/CallScript";

export function useCallManager(host: string) {
  const { trackEvent } = useAnalytics();
  const [calls, setCalls] = useState<Call[]>([]);
  const [selectedCall, setSelectedCall] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [logs, setLogs] = useState<LogMessage[]>([]);
  const { organization } = useOrganization();
  const { user } = useUser();

  const mainSocket = usePartySocket({
    host,
    room: organization?.slug ?? "waiting-room",
    onMessage(event) {
      const data = JSON.parse(event.data);
      console.log("Received message:", data);
      switch (data.type) {
        case "ACTIVE_CALLS":
          setCalls(data.payload);
          break;
        case "CALL_CREATED":
          setCalls((prev) => [...prev, data.payload]);
          setSelectedCall(data.payload.id);
          trackEvent("call_created", {
            roomId: data.payload.id,
            url: data.payload.url,
          });
          break;
        case "CALL_DELETED":
          setCalls((prev) =>
            prev.filter((call) => call.id !== data.payload.roomId)
          );
          if (selectedCall === data.payload.roomId) {
            setSelectedCall(null);
          }
          trackEvent("call_deleted", {
            roomId: data.payload.roomId,
          });
          break;
        case "CALL_CREATION_ERROR":
          console.error("Call creation error:", data.payload.error);
          setError(data.payload.error);
          trackEvent("call_creation_error", {
            error: data.payload.error,
          });
          break;
        case "LOG":
          setLogs((prevLogs) => [...prevLogs, data.payload]);
          console.log("Server log:", data.payload.message);
          break;
      }
    },
  });

  const createCall = useCallback(
    (url: string, callScript: CallScript) => {
      setError(null);
      mainSocket.send(
        JSON.stringify({
          type: "CREATE_CALL",
          payload: { url, userId: user?.id, callScript },
        })
      );
    },
    [mainSocket, user?.id]
  );

  const deleteCall = useCallback(
    (roomId: string) => {
      mainSocket.send(
        JSON.stringify({ type: "DELETE_CALL", payload: { roomId } })
      );
    },
    [mainSocket]
  );

  const selectCall = useCallback(
    (roomId: string) => {
      setSelectedCall(roomId);
      trackEvent("user_selected_call", { roomId });
    },
    [trackEvent]
  );

  const leaveCall = useCallback(() => {
    setSelectedCall(null);
    trackEvent("user_returned_to_all_calls");
  }, [trackEvent]);

  const clearLogs = useCallback(() => {
    setLogs([]);
  }, []);

  return {
    calls,
    host,
    selectedCall,
    error,
    logs,
    createCall,
    deleteCall,
    selectCall,
    leaveCall,
    clearLogs,
  };
}
