import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";

export interface SettingsMenuProps {
  activeMode: "knowledge" | "script";
  setActiveMode: (mode: "knowledge" | "script") => void;
}
export function SettingsMenu({
  activeMode,
  setActiveMode,
}: Readonly<SettingsMenuProps>) {
  return (
    <NavigationMenu
      value={activeMode}
      onValueChange={(value) => {
        if (["script", "knowledge"].includes(value)) {
          setActiveMode(value as "knowledge" | "script");
        }
      }}
    >
      <NavigationMenuList>
        <NavigationMenuItem value="aihelp">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "knowledge" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("knowledge");
            }}
          >
            Knowledge
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem value="script">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "script" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("script");
            }}
          >
            Call scripts
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
