import { Loader2 } from "lucide-react";
import { useState, useEffect } from "react";

export interface LoadingSpinnerProps {
  delay?: number;
  reason?: string;
}
export function LoadingSpinner({
  delay = 2000,
  reason,
}: Readonly<LoadingSpinnerProps>) {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), delay);
    return () => clearTimeout(timer);
  }, []);

  if (!showSpinner) {
    return null;
  }

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="flex items-center justify-center h-full">
      <Loader2
        className="animate-spin h-16 w-16 text-gray-900 cursor-pointer"
        onClick={handleReload}
        data-reason={reason}
      />
    </div>
  );
}
