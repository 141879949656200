import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { type PlaybookProgress, type PlaybookSection } from "party/types";
import {
  Check,
  ArrowRight,
  CheckCheck,
  CheckCheckIcon,
  SquareCheck,
  Square,
} from "lucide-react";
import ReactMarkdown from "react-markdown";

export interface PlaybookDisplayProps {
  playbook: PlaybookProgress;
}

export function PlaybookDisplay({ playbook }: Readonly<PlaybookDisplayProps>) {
  const currentSection = playbook.sections.find(
    (section) => section.id === playbook.currentSectionId
  );

  return (
    <div className="flex flex-col h-full">
      <div className="flex gap-4 flex-grow">
        <Card className="flex-1">
          <ScrollArea className="flex-grow">
            <ul className="space-y-2 p-4">
              {playbook.sections.map((section) => (
                <PlaybookItem
                  key={section.id}
                  section={section}
                  isCurrent={section.id === playbook.currentSectionId}
                />
              ))}
            </ul>
          </ScrollArea>
        </Card>
        {currentSection && (
          <Card className="flex-1">
            <ScrollArea className="flex-grow">
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">
                  {currentSection.name}
                </h3>
                <ul className="space-y-2">
                  {currentSection.scriptItems.map((item, index) => (
                    <li key={index} className="flex items-start space-x-2">
                      {item.completed ? (
                        <SquareCheck className="w-4 h-4 flex-shrink-0 inline-block mt-1 mr-2" />
                      ) : (
                        <Square className="w-4 h-4 flex-shrink-0 inline-block mt-1 mr-2" />
                      )}
                      <span>{item.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </ScrollArea>
          </Card>
        )}
      </div>
    </div>
  );
}

export interface PlaybookItemProps {
  section: PlaybookSection;
  isCurrent: boolean;
}

function PlaybookItem({ section, isCurrent }: Readonly<PlaybookItemProps>) {
  const isComplete = section.status === "complete";
  const isInProgress = section.status === "in_progress";

  return (
    <li
      className={`flex items-center gap-2 p-2 rounded ${
        isCurrent ? "bg-blue-100" : "hover:bg-gray-100"
      }`}
    >
      {isComplete ? (
        <Check size={16} className="text-green-600" />
      ) : isCurrent ? (
        <ArrowRight size={16} className="text-blue-600" />
      ) : (
        <div className="w-4 h-4" />
      )}
      <div className="flex-grow">
        <span
          className={
            isComplete
              ? "text-green-600"
              : isInProgress
                ? "text-blue-600"
                : "text-gray-600"
          }
        >
          {section.name}
        </span>
      </div>
    </li>
  );
}
