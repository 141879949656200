import { FaGoogle, FaMicrosoft, FaVideo } from "react-icons/fa";
import { SiZoom } from "react-icons/si";

interface CallServiceIconProps {
  url: string;
}

export function CallServiceIcon({ url }: Readonly<CallServiceIconProps>) {
  const parsedUrl = new URL(url);
  const hostname = parsedUrl.hostname;

  if (hostname.includes("google")) {
    const roomId = parsedUrl.pathname.split("/").pop();
    return (
      <div className="flex items-center">
        <FaGoogle className="mr-2" />
        <span>{roomId}</span>
      </div>
    );
  } else if (hostname.includes("zoom")) {
    const roomId = parsedUrl.pathname.split("/").pop();
    return (
      <div className="flex items-center">
        <SiZoom className="mr-2" />
        <span>{roomId}</span>
      </div>
    );
  } else if (hostname.includes("teams.microsoft")) {
    return (
      <div className="flex items-center">
        <FaMicrosoft className="mr-2" />
        <span>Teams Meeting</span>
      </div>
    );
  } else {
    // Default case for unsupported or unknown services
    return (
      <div className="flex items-center">
        <FaVideo className="mr-2" />
        <span>{hostname}</span>
      </div>
    );
  }
}
