import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import useLocalStorageState from "use-local-storage-state";

import { RecallBot } from "party/types";
import {
  CheckCircle2,
  FilterIcon,
  MessageCircleQuestion,
  Sparkles,
} from "lucide-react";
import usePartySocket from "partysocket/react";
import { useCallback, useState } from "react";
import { Question } from "party/questionsMessageTypes";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AiHelp } from "./AiHelpBrowser";

export interface QuestionDisplayProps {
  host: string;
  roomId: string;
  onGetAIHelp: (question: string) => void;
  roomBot: RecallBot | undefined;
  aiHistory: AiHelp[];
}

export type DisplayFilter = "all" | "host" | "guest";

export function QuestionDisplay({
  host,
  roomId,
  onGetAIHelp,
  roomBot,
  aiHistory,
}: Readonly<QuestionDisplayProps>) {
  const [displayFilter, setDisplayFilter] = useLocalStorageState<DisplayFilter>(
    "question-display-filter",
    {
      defaultValue: "guest",
    }
  );
  const [callQuestions, setCallQuestions] = useState<Question[]>([]);
  const questionSocket = usePartySocket({
    host,
    room: roomId,
    party: "questions",
    onMessage(event) {
      const data = JSON.parse(event.data);
      if (data.type === "QUESTIONS") {
        setCallQuestions(
          data.payload.sort((a: Question, b: Question) =>
            b.id.localeCompare(a.id)
          )
        );
      }
      if (data.type === "QUESTION") {
        const newQuestion = data.payload;
        setCallQuestions((prevQuestions) => {
          const existingIndex = prevQuestions.findIndex(
            (q) => q.id === newQuestion.id
          );
          let updatedQuestions;
          if (existingIndex !== -1) {
            // Replace existing question
            updatedQuestions = [
              ...prevQuestions.slice(0, existingIndex),
              newQuestion,
              ...prevQuestions.slice(existingIndex + 1),
            ];
          } else {
            // Append new question
            updatedQuestions = [...prevQuestions, newQuestion];
          }
          return updatedQuestions.sort((a, b) => b.id.localeCompare(a.id));
        });
      }
    },
  });

  const handleAnswerQuestion = useCallback(
    (questionId: string) => {
      questionSocket.send(
        JSON.stringify({
          type: "ANSWER_QUESTION",
          payload: { questionId },
        })
      );
    },
    [questionSocket]
  );

  const displayedQuestions = callQuestions.filter((q) => {
    const speaker = roomBot?.meeting_participants?.find(
      (p) => p.id === q.speaker_id
    );
    if (!speaker) return false;

    switch (displayFilter) {
      case "all":
        return true;
      case "host":
        return speaker.is_host;
      case "guest":
        return !speaker.is_host;
      default:
        return false;
    }
  });
  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-row w-full">
        <div className="flex-grow">
          <h2 className="mb-2">Questions</h2>
        </div>
        <div className="flex-grow-0">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="sm" variant="ghost" className="-mt-1 mb-1">
                <span className="mr-2">
                  {displayFilter.charAt(0).toUpperCase() +
                    displayFilter.slice(1)}
                </span>{" "}
                <FilterIcon size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setDisplayFilter("guest")}>
                Guest
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setDisplayFilter("host")}>
                Host
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setDisplayFilter("all")}>
                All
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Card className="flex-grow overflow-hidden flex flex-col">
        <ScrollArea className="flex-grow">
          <QuestionsList
            onGetAIHelp={onGetAIHelp}
            questions={displayedQuestions}
            onAnswerQuestion={handleAnswerQuestion}
            aiHistory={aiHistory}
          />
        </ScrollArea>
      </Card>
    </div>
  );
}

const QuestionsList = ({
  questions,
  onAnswerQuestion,
  onGetAIHelp,
  aiHistory,
}: {
  questions: Question[];
  onAnswerQuestion: (questionId: string) => void;
  onGetAIHelp: (question: string) => void;
  aiHistory: AiHelp[];
}) => {
  return (
    <ul className="space-y-2">
      {questions.map((question) => (
        <QuestionItem
          key={question.id}
          question={question}
          onAnswerQuestion={onAnswerQuestion}
          onGetAIHelp={onGetAIHelp}
          aiHelp={aiHistory.find((ai) => ai.questionId === question.id)}
        />
      ))}
      {questions.length === 0 && (
        <p className="text-sm text-gray-400 p-4">
          Questions asked during the call will appear here
        </p>
      )}
    </ul>
  );
};
interface QuestionItemProps {
  aiHelp: AiHelp | undefined;
  question: Question;
  onAnswerQuestion: (questionId: string) => void;
  onGetAIHelp: (question: string) => void;
}

function QuestionItem({
  aiHelp,
  question,
  onAnswerQuestion,
  onGetAIHelp,
}: Readonly<QuestionItemProps>) {
  return (
    <li className="flex flex-col gap-2 p-2 border-b last:border-b-0">
      <div className="flex justify-between items-start space-x-2">
        <div className="flex-grow-0">
          <Button
            size="sm"
            variant={"ghost"}
            onClick={() => onAnswerQuestion(question.id)}
            disabled={question.isAnswered}
          >
            {question.isAnswered ? (
              <CheckCircle2 size={24} />
            ) : (
              <MessageCircleQuestion size={24} />
            )}
          </Button>
        </div>
        <div className="font-semibold flex-grow">
          <div className="py-1">{question.question}</div>
        </div>
        <div className="flex-grow-0">
          <Button
            size="sm"
            variant="outline"
            onClick={() => onGetAIHelp(question.question)}
          >
            <Sparkles size={16} className="mr-1" />
          </Button>
        </div>
      </div>
      <div className="text-sm text-gray-600 px-2">{question.context}</div>
      {aiHelp && (
        <div className="text-sm text-gray-600 px-2">{aiHelp.answer}</div>
      )}
    </li>
  );
}
