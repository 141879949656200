import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import yaml from "js-yaml";
import {
  CallScript,
  CallScriptSection,
  CallScriptSectionSchema,
} from "./types/CallScript";
import CodeMirror from "@uiw/react-codemirror";
import { yaml as yamlLang } from "@codemirror/lang-yaml";
import { solarizedLight } from "@uiw/codemirror-theme-solarized";

interface CallScriptEditorProps {
  script: CallScript;
  onSave: (script: CallScript) => void;
  onClose: () => void;
}

const exampleScript = `- title: Introduction
  items:
    - "Hello, this is [Your Name] from [Company Name]. How are you doing today?"
    - "I'm calling to discuss how our [product/service] might benefit your business. Do you have a few minutes to chat?"
- title: Needs Assessment
  items:
    - "Can you tell me a bit about your current [relevant process/situation]?"
    - "What challenges are you facing with [specific area related to your product/service]?"
    - "What are your main goals for improving this area of your business?"
- title: Solution Presentation
  items:
    - "Based on what you've shared, I think our [product/service] could really help. It's designed to [key benefit 1] and [key benefit 2]."
    - "One of the features our clients find most valuable is [specific feature]. This could address your concern about [mentioned pain point]."
    - "I understand you mentioned [potential objection]. Many of our clients initially felt the same way, but they found that [explanation of how objection is addressed]."
- title: Close
  items:
    - "To summarize, our [product/service] can help you [recap main benefits tailored to their needs]."
    - "The next step would be to set up a demo where we can show you exactly how this would work for your specific situation. How does [suggest day/time] work for you?"
    - "Thank you so much for your time today. I'm looking forward to our demo and showing you how we can help improve your [relevant area of business]."`;

export function CallScriptEditor({
  script,
  onSave,
  onClose,
}: CallScriptEditorProps) {
  const [editedSections, setEditedSections] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    if (script.sections) {
      const yamlContent = yaml.dump(script.sections, {
        lineWidth: -1,
        noRefs: true,
        quotingType: '"',
      });
      setEditedSections(yamlContent);
    } else {
      setEditedSections(exampleScript);
    }
  }, [script]);

  const validateYaml = (yamlString: string): boolean => {
    try {
      const parsedYaml = yaml.load(yamlString) as CallScriptSection[];
      CallScriptSectionSchema.array().parse(parsedYaml);
      setValidationError(null);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setValidationError(error.message);
      } else {
        setValidationError("An unknown error occurred");
      }
      return false;
    }
  };

  const handleSave = () => {
    if (validateYaml(editedSections)) {
      try {
        const parsedSections = yaml.load(editedSections) as CallScriptSection[];
        const updatedScript: CallScript = {
          ...script,
          sections: parsedSections,
          updatedAt: new Date().toISOString(),
        };
        onSave(updatedScript);
      } catch (error) {
        console.error("Error parsing YAML:", error);
        setValidationError(
          "Error parsing script content. Please check your input."
        );
      }
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">
          Edit Call Script: {script.title}
        </h2>
        <Button variant="ghost" onClick={onClose}>
          <X size={24} />
        </Button>
      </div>
      <p className="text-sm text-gray-500 mb-2">
        Edit the script sections below. Each section should have a title and a
        list of items.
      </p>
      <CodeMirror
        value={editedSections}
        height="400px"
        extensions={[yamlLang()]}
        theme={solarizedLight}
        onChange={(value) => {
          setEditedSections(value);
          validateYaml(value);
        }}
        className="mb-4"
      />
      {validationError && (
        <div className="text-red-500 mb-4">
          {validationError.split("\n").map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
      <div>
        <Button onClick={handleSave} disabled={!!validationError}>
          Save
        </Button>
      </div>
    </div>
  );
}
