import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Sparkles } from "lucide-react";

interface AiHelpAskProps {
  onAskQuestion: (question: string) => void;
}

export function AiHelpAsk({ onAskQuestion }: Readonly<AiHelpAskProps>) {
  const [question, setQuestion] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (question.trim()) {
      onAskQuestion(question);
      setQuestion("");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
      <div className="flex items-center space-x-2">
        <Sparkles size={16} className="text-primary" />
        <h2 className="font-bold text-md">Get AI help</h2>
      </div>
      <div className="flex space-x-2">
        <Input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Type your question here..."
          className="flex-grow"
        />
        <Button type="submit" disabled={!question.trim()}>
          Ask
        </Button>
      </div>
    </form>
  );
}
