import { Button } from "@/components/ui/button";
import {
  CompressedTable,
  CompressedTableBody,
  CompressedTableCell,
  CompressedTableHead,
  CompressedTableHeader,
  CompressedTableRow,
} from "@/components/ui/table-compressed";
import { Sparkles } from "lucide-react";
import type { TranscriptUpdate } from "party/types";
import { SmoothScrollArea } from "@/components/ui/smooth-scroll-area";
import { useEffect, useRef } from "react";

export interface TranscriptTableProps {
  transcript: TranscriptUpdate[];
  handleGetHelp: (question?: string) => void;
}

export interface Speaker {
  initials: string;
  name: string;
  id: number;
  color: string;
}

export function TranscriptTable({
  transcript,
  handleGetHelp,
}: Readonly<TranscriptTableProps>) {
  const scrollAreaRef = useRef<
    HTMLDivElement & { smoothScrollTo: (options?: ScrollToOptions) => void }
  >(null);

  const getSpeakerInitials = (name: string) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();

  const speakers = transcript.reduce(
    (acc, item) => {
      if (!acc[item.speaker_id]) {
        const initials = getSpeakerInitials(item.speaker);
        const count = Object.values(acc).filter((s) =>
          s.initials.startsWith(initials)
        ).length;
        acc[item.speaker_id] = {
          initials: count > 0 ? `${initials}${count + 1}` : initials,
          name: item.speaker,
          id: item.speaker_id,
          color: `hsl(${(item.speaker_id * 137) % 360}, 70%, 40%)`,
        };
      }
      return acc;
    },
    {} as Record<number, Speaker>
  );

  // Group consecutive items by speaker
  const groupedTranscript = transcript
    .slice() // Create a copy to avoid mutating the original array
    .sort((a, b) => a.words[0].start_time - b.words[0].start_time)
    .reduce((acc, item) => {
      if (
        acc.length === 0 ||
        acc[acc.length - 1][0].speaker_id !== item.speaker_id
      ) {
        acc.push([item]);
      } else {
        acc[acc.length - 1].push(item);
      }
      return acc;
    }, [] as TranscriptUpdate[][]);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [transcript]);

  return (
    <>
      <SmoothScrollArea ref={scrollAreaRef} className="h-[400px]" smoothScroll>
        <CompressedTable>
          <CompressedTableBody>
            {groupedTranscript.map((group) => (
              <TranscriptTableItem
                speakers={speakers}
                key={group[0].id}
                items={group}
                handleGetHelp={handleGetHelp}
              />
            ))}
          </CompressedTableBody>
        </CompressedTable>
      </SmoothScrollArea>
    </>
  );
}

export interface TranscriptTableItemProps {
  items: TranscriptUpdate[];
  handleGetHelp: (question?: string) => void;
  speakers: Record<number, Speaker>;
}

export function TranscriptTableItem({
  items,
  handleGetHelp,
  speakers,
}: Readonly<TranscriptTableItemProps>) {
  const speaker = speakers[items[0].speaker_id];
  const words = items
    .map((item) =>
      item.words
        .map((word) => word.text)
        .join(" ")
        .trim()
    )
    .join(" ")
    .replace(/\s+/g, " ")
    .trim();

  return (
    <CompressedTableRow key={items[0].id} className="align-top">
      <CompressedTableCell className="align-top">
        <span
          className={`inline-block px-2 py-1 rounded-full text-white text-center`}
          style={{ backgroundColor: speaker.color }}
        >
          <span className="inline-block min-w-[3ch]">{speaker.initials}</span>
        </span>
      </CompressedTableCell>
      <CompressedTableCell>{words}</CompressedTableCell>
      <CompressedTableCell className="text-right align-top">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            handleGetHelp(words);
          }}
          aria-label="Get help"
        >
          <Sparkles size={16} />
        </Button>
      </CompressedTableCell>
    </CompressedTableRow>
  );
}
