import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useOrganization } from "@clerk/clerk-react";
import { Separator } from "@/components/ui/separator";
import { CallScriptList } from "./CallScriptList";
import { CallScriptCreator } from "./CallScriptCreator";
import { CallScriptEditor } from "./CallScriptEditor";
import { CallScript, CallScriptSchema } from "./types/CallScript";
import PartySocket from "partysocket";
import z from "zod";

export const CallScriptsResponseSchema = z.array(CallScriptSchema);

export type CallScriptsResponse = z.infer<typeof CallScriptsResponseSchema>;

export function CallScriptManagerUI({
  partySocket,
}: {
  partySocket: PartySocket;
}) {
  const { organization } = useOrganization();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const [editingScriptId, setEditingScriptId] = useState<string | null>(null);

  const fetchCallScripts = async (orgId: string): Promise<CallScript[]> => {
    const response = await PartySocket.fetch(
      {
        host: partySocket.host,
        party: "main",
        room: partySocket.room ?? "waiting-room",
        path: "api/callscripts/list",
      },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orgId }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return CallScriptsResponseSchema.parse(result);
  };

  const createCallScript = async (title: string) => {
    if (!organization) return;

    const response = await PartySocket.fetch(
      {
        host: partySocket.host,
        party: "main",
        room: partySocket.room ?? "waiting-room",
        path: "api/callscripts/create",
      },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ title, orgId: organization.id }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return CallScriptSchema.parse(result);
  };

  const updateCallScript = async (script: CallScript) => {
    if (!organization) return;

    const response = await PartySocket.fetch(
      {
        host: partySocket.host,
        party: "main",
        room: partySocket.room ?? "waiting-room",
        path: "api/callscripts/update",
      },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ script, orgId: organization.id }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return CallScriptSchema.parse(result);
  };

  const {
    data: callScripts,
    isLoading,
    isError,
  } = useQuery<CallScriptsResponse, Error>({
    queryKey: ["callScripts", organization?.id],
    queryFn: () => fetchCallScripts(organization?.id || ""),
    enabled: !!organization?.id,
  });

  const createMutation = useMutation({
    mutationFn: createCallScript,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["callScripts", organization?.id],
      });
      setIsCreating(false);
    },
    onError: (error) => {
      console.error("Error creating call script:", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateCallScript,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["callScripts", organization?.id],
      });
      setEditingScriptId(null);
    },
    onError: (error) => {
      console.error("Error updating call script:", error);
    },
  });

  const handleCreate = (title: string) => {
    if (organization?.id) {
      createMutation.mutate(title);
    }
  };

  const handleEdit = (id: string) => {
    setEditingScriptId(id);
  };

  const handleSave = (updatedScript: CallScript) => {
    console.log("Saving script:", updatedScript);
    updateMutation.mutate(updatedScript);
  };

  const handleUpdateTitle = (updatedScript: CallScript) => {
    updateMutation.mutate(updatedScript);
  };

  const editingScript = editingScriptId
    ? callScripts?.find((script) => script.id === editingScriptId)
    : null;

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex-grow overflow-hidden">
        <div className="h-full flex flex-col space-y-8 p-4">
          {editingScript ? (
            <CallScriptEditor
              script={editingScript}
              onSave={handleSave}
              onClose={() => setEditingScriptId(null)}
            />
          ) : (
            <>
              <CallScriptCreator
                onCreate={handleCreate}
                isCreating={createMutation.isPending}
              />
              <Separator />
              <div className="flex-grow overflow-hidden">
                <CallScriptList
                  callScripts={callScripts || []}
                  isLoading={isLoading}
                  isError={isError}
                  onEdit={handleEdit}
                  onUpdateTitle={handleUpdateTitle}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
